/**
 * DreamPay REST API
 * API to support DreamPay applications
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { PaymentLinks } from '../model/paymentLinks';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PaymentLinksService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentLinksCancelIdPut(id: string, observe?: 'body', reportProgress?: boolean): Observable<PaymentLinks>;
    public apiPaymentLinksCancelIdPut(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PaymentLinks>>;
    public apiPaymentLinksCancelIdPut(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PaymentLinks>>;
    public apiPaymentLinksCancelIdPut(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiPaymentLinksCancelIdPut.');
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["ApiKey"]) {
            headers = headers.set('ApiKey', this.configuration.apiKeys["ApiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PaymentLinks>('put',`${this.basePath}/api/PaymentLinks/cancel/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param emailAddress 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentLinksEmailEmailAddressGet(emailAddress: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PaymentLinks>>;
    public apiPaymentLinksEmailEmailAddressGet(emailAddress: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PaymentLinks>>>;
    public apiPaymentLinksEmailEmailAddressGet(emailAddress: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PaymentLinks>>>;
    public apiPaymentLinksEmailEmailAddressGet(emailAddress: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (emailAddress === null || emailAddress === undefined) {
            throw new Error('Required parameter emailAddress was null or undefined when calling apiPaymentLinksEmailEmailAddressGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["ApiKey"]) {
            headers = headers.set('ApiKey', this.configuration.apiKeys["ApiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<PaymentLinks>>('get',`${this.basePath}/api/PaymentLinks/email/${encodeURIComponent(String(emailAddress))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param establishmentID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentLinksEstablishmentEstablishmentIDGet(establishmentID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<PaymentLinks>>;
    public apiPaymentLinksEstablishmentEstablishmentIDGet(establishmentID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PaymentLinks>>>;
    public apiPaymentLinksEstablishmentEstablishmentIDGet(establishmentID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PaymentLinks>>>;
    public apiPaymentLinksEstablishmentEstablishmentIDGet(establishmentID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (establishmentID === null || establishmentID === undefined) {
            throw new Error('Required parameter establishmentID was null or undefined when calling apiPaymentLinksEstablishmentEstablishmentIDGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["ApiKey"]) {
            headers = headers.set('ApiKey', this.configuration.apiKeys["ApiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<PaymentLinks>>('get',`${this.basePath}/api/PaymentLinks/establishment/${encodeURIComponent(String(establishmentID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentLinksGet(observe?: 'body', reportProgress?: boolean): Observable<Array<PaymentLinks>>;
    public apiPaymentLinksGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PaymentLinks>>>;
    public apiPaymentLinksGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PaymentLinks>>>;
    public apiPaymentLinksGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["ApiKey"]) {
            headers = headers.set('ApiKey', this.configuration.apiKeys["ApiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<PaymentLinks>>('get',`${this.basePath}/api/PaymentLinks`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentLinksIdDelete(id: string, observe?: 'body', reportProgress?: boolean): Observable<PaymentLinks>;
    public apiPaymentLinksIdDelete(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PaymentLinks>>;
    public apiPaymentLinksIdDelete(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PaymentLinks>>;
    public apiPaymentLinksIdDelete(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiPaymentLinksIdDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["ApiKey"]) {
            headers = headers.set('ApiKey', this.configuration.apiKeys["ApiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PaymentLinks>('delete',`${this.basePath}/api/PaymentLinks/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentLinksIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<PaymentLinks>;
    public apiPaymentLinksIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PaymentLinks>>;
    public apiPaymentLinksIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PaymentLinks>>;
    public apiPaymentLinksIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiPaymentLinksIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["ApiKey"]) {
            headers = headers.set('ApiKey', this.configuration.apiKeys["ApiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PaymentLinks>('get',`${this.basePath}/api/PaymentLinks/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentLinksIdPut(id: string, body?: PaymentLinks, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPaymentLinksIdPut(id: string, body?: PaymentLinks, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPaymentLinksIdPut(id: string, body?: PaymentLinks, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPaymentLinksIdPut(id: string, body?: PaymentLinks, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiPaymentLinksIdPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["ApiKey"]) {
            headers = headers.set('ApiKey', this.configuration.apiKeys["ApiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/PaymentLinks/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param emailAddress 
     * @param number 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentLinksLastEmailAddressNumberGet(emailAddress: string, number: number, observe?: 'body', reportProgress?: boolean): Observable<Array<PaymentLinks>>;
    public apiPaymentLinksLastEmailAddressNumberGet(emailAddress: string, number: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PaymentLinks>>>;
    public apiPaymentLinksLastEmailAddressNumberGet(emailAddress: string, number: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PaymentLinks>>>;
    public apiPaymentLinksLastEmailAddressNumberGet(emailAddress: string, number: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (emailAddress === null || emailAddress === undefined) {
            throw new Error('Required parameter emailAddress was null or undefined when calling apiPaymentLinksLastEmailAddressNumberGet.');
        }

        if (number === null || number === undefined) {
            throw new Error('Required parameter number was null or undefined when calling apiPaymentLinksLastEmailAddressNumberGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["ApiKey"]) {
            headers = headers.set('ApiKey', this.configuration.apiKeys["ApiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<PaymentLinks>>('get',`${this.basePath}/api/PaymentLinks/last/${encodeURIComponent(String(emailAddress))}/${encodeURIComponent(String(number))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param shortenedCode 
     * @param emailAddress 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentLinksLinkShortenedCodeEmailEmailAddressPatch(shortenedCode: string, emailAddress: string, observe?: 'body', reportProgress?: boolean): Observable<PaymentLinks>;
    public apiPaymentLinksLinkShortenedCodeEmailEmailAddressPatch(shortenedCode: string, emailAddress: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PaymentLinks>>;
    public apiPaymentLinksLinkShortenedCodeEmailEmailAddressPatch(shortenedCode: string, emailAddress: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PaymentLinks>>;
    public apiPaymentLinksLinkShortenedCodeEmailEmailAddressPatch(shortenedCode: string, emailAddress: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shortenedCode === null || shortenedCode === undefined) {
            throw new Error('Required parameter shortenedCode was null or undefined when calling apiPaymentLinksLinkShortenedCodeEmailEmailAddressPatch.');
        }

        if (emailAddress === null || emailAddress === undefined) {
            throw new Error('Required parameter emailAddress was null or undefined when calling apiPaymentLinksLinkShortenedCodeEmailEmailAddressPatch.');
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["ApiKey"]) {
            headers = headers.set('ApiKey', this.configuration.apiKeys["ApiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PaymentLinks>('patch',`${this.basePath}/api/PaymentLinks/link/${encodeURIComponent(String(shortenedCode))}/email/${encodeURIComponent(String(emailAddress))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentLinksPost(body?: PaymentLinks, observe?: 'body', reportProgress?: boolean): Observable<PaymentLinks>;
    public apiPaymentLinksPost(body?: PaymentLinks, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PaymentLinks>>;
    public apiPaymentLinksPost(body?: PaymentLinks, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PaymentLinks>>;
    public apiPaymentLinksPost(body?: PaymentLinks, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["ApiKey"]) {
            headers = headers.set('ApiKey', this.configuration.apiKeys["ApiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PaymentLinks>('post',`${this.basePath}/api/PaymentLinks`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param shortenedCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentLinksShortenedShortenedCodeGet(shortenedCode: string, observe?: 'body', reportProgress?: boolean): Observable<PaymentLinks>;
    public apiPaymentLinksShortenedShortenedCodeGet(shortenedCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PaymentLinks>>;
    public apiPaymentLinksShortenedShortenedCodeGet(shortenedCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PaymentLinks>>;
    public apiPaymentLinksShortenedShortenedCodeGet(shortenedCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shortenedCode === null || shortenedCode === undefined) {
            throw new Error('Required parameter shortenedCode was null or undefined when calling apiPaymentLinksShortenedShortenedCodeGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["ApiKey"]) {
            headers = headers.set('ApiKey', this.configuration.apiKeys["ApiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PaymentLinks>('get',`${this.basePath}/api/PaymentLinks/shortened/${encodeURIComponent(String(shortenedCode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
