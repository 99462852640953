/**
 * DreamPay REST API
 * API to support DreamPay applications
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Transactions } from '../model/transactions';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class TransactionsService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionsCancelAdminIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<Transactions>;
    public apiTransactionsCancelAdminIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Transactions>>;
    public apiTransactionsCancelAdminIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Transactions>>;
    public apiTransactionsCancelAdminIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiTransactionsCancelAdminIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["ApiKey"]) {
            headers = headers.set('ApiKey', this.configuration.apiKeys["ApiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Transactions>('get',`${this.basePath}/api/Transactions/cancel-admin/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionsCancelIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<Transactions>;
    public apiTransactionsCancelIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Transactions>>;
    public apiTransactionsCancelIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Transactions>>;
    public apiTransactionsCancelIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiTransactionsCancelIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["ApiKey"]) {
            headers = headers.set('ApiKey', this.configuration.apiKeys["ApiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Transactions>('get',`${this.basePath}/api/Transactions/cancel/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionsCancelManualIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<Transactions>;
    public apiTransactionsCancelManualIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Transactions>>;
    public apiTransactionsCancelManualIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Transactions>>;
    public apiTransactionsCancelManualIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiTransactionsCancelManualIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["ApiKey"]) {
            headers = headers.set('ApiKey', this.configuration.apiKeys["ApiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Transactions>('get',`${this.basePath}/api/Transactions/cancel-manual/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param cardNumber 
     * @param authorizationCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionsCardCardNumberAuthorizationCodeAuthorizationCodeGet(cardNumber: string, authorizationCode: string, observe?: 'body', reportProgress?: boolean): Observable<Transactions>;
    public apiTransactionsCardCardNumberAuthorizationCodeAuthorizationCodeGet(cardNumber: string, authorizationCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Transactions>>;
    public apiTransactionsCardCardNumberAuthorizationCodeAuthorizationCodeGet(cardNumber: string, authorizationCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Transactions>>;
    public apiTransactionsCardCardNumberAuthorizationCodeAuthorizationCodeGet(cardNumber: string, authorizationCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (cardNumber === null || cardNumber === undefined) {
            throw new Error('Required parameter cardNumber was null or undefined when calling apiTransactionsCardCardNumberAuthorizationCodeAuthorizationCodeGet.');
        }

        if (authorizationCode === null || authorizationCode === undefined) {
            throw new Error('Required parameter authorizationCode was null or undefined when calling apiTransactionsCardCardNumberAuthorizationCodeAuthorizationCodeGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["ApiKey"]) {
            headers = headers.set('ApiKey', this.configuration.apiKeys["ApiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Transactions>('get',`${this.basePath}/api/Transactions/card/${encodeURIComponent(String(cardNumber))}/authorizationCode/${encodeURIComponent(String(authorizationCode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param emailAddress 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionsEmailEmailAddressGet(emailAddress: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Transactions>>;
    public apiTransactionsEmailEmailAddressGet(emailAddress: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Transactions>>>;
    public apiTransactionsEmailEmailAddressGet(emailAddress: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Transactions>>>;
    public apiTransactionsEmailEmailAddressGet(emailAddress: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (emailAddress === null || emailAddress === undefined) {
            throw new Error('Required parameter emailAddress was null or undefined when calling apiTransactionsEmailEmailAddressGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["ApiKey"]) {
            headers = headers.set('ApiKey', this.configuration.apiKeys["ApiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Transactions>>('get',`${this.basePath}/api/Transactions/email/${encodeURIComponent(String(emailAddress))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param establishmentID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionsEstablishmentEstablishmentIDGet(establishmentID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Transactions>>;
    public apiTransactionsEstablishmentEstablishmentIDGet(establishmentID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Transactions>>>;
    public apiTransactionsEstablishmentEstablishmentIDGet(establishmentID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Transactions>>>;
    public apiTransactionsEstablishmentEstablishmentIDGet(establishmentID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (establishmentID === null || establishmentID === undefined) {
            throw new Error('Required parameter establishmentID was null or undefined when calling apiTransactionsEstablishmentEstablishmentIDGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["ApiKey"]) {
            headers = headers.set('ApiKey', this.configuration.apiKeys["ApiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Transactions>>('get',`${this.basePath}/api/Transactions/establishment/${encodeURIComponent(String(establishmentID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<Transactions>>;
    public apiTransactionsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Transactions>>>;
    public apiTransactionsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Transactions>>>;
    public apiTransactionsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["ApiKey"]) {
            headers = headers.set('ApiKey', this.configuration.apiKeys["ApiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Transactions>>('get',`${this.basePath}/api/Transactions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionsIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<Transactions>;
    public apiTransactionsIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Transactions>>;
    public apiTransactionsIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Transactions>>;
    public apiTransactionsIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiTransactionsIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["ApiKey"]) {
            headers = headers.set('ApiKey', this.configuration.apiKeys["ApiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Transactions>('get',`${this.basePath}/api/Transactions/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionsIdPut(id: number, body?: Transactions, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiTransactionsIdPut(id: number, body?: Transactions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiTransactionsIdPut(id: number, body?: Transactions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiTransactionsIdPut(id: number, body?: Transactions, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiTransactionsIdPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["ApiKey"]) {
            headers = headers.set('ApiKey', this.configuration.apiKeys["ApiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/Transactions/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param emailAddress 
     * @param number 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionsLastEmailAddressNumberGet(emailAddress: string, number: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Transactions>>;
    public apiTransactionsLastEmailAddressNumberGet(emailAddress: string, number: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Transactions>>>;
    public apiTransactionsLastEmailAddressNumberGet(emailAddress: string, number: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Transactions>>>;
    public apiTransactionsLastEmailAddressNumberGet(emailAddress: string, number: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (emailAddress === null || emailAddress === undefined) {
            throw new Error('Required parameter emailAddress was null or undefined when calling apiTransactionsLastEmailAddressNumberGet.');
        }

        if (number === null || number === undefined) {
            throw new Error('Required parameter number was null or undefined when calling apiTransactionsLastEmailAddressNumberGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["ApiKey"]) {
            headers = headers.set('ApiKey', this.configuration.apiKeys["ApiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Transactions>>('get',`${this.basePath}/api/Transactions/last/${encodeURIComponent(String(emailAddress))}/${encodeURIComponent(String(number))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param posID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionsLastPosIDGet(posID: string, observe?: 'body', reportProgress?: boolean): Observable<Transactions>;
    public apiTransactionsLastPosIDGet(posID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Transactions>>;
    public apiTransactionsLastPosIDGet(posID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Transactions>>;
    public apiTransactionsLastPosIDGet(posID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (posID === null || posID === undefined) {
            throw new Error('Required parameter posID was null or undefined when calling apiTransactionsLastPosIDGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["ApiKey"]) {
            headers = headers.set('ApiKey', this.configuration.apiKeys["ApiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Transactions>('get',`${this.basePath}/api/Transactions/last/${encodeURIComponent(String(posID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param paymentLink 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionsPaymentLinkPaymentLinkCountGet(paymentLink: string, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public apiTransactionsPaymentLinkPaymentLinkCountGet(paymentLink: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public apiTransactionsPaymentLinkPaymentLinkCountGet(paymentLink: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public apiTransactionsPaymentLinkPaymentLinkCountGet(paymentLink: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (paymentLink === null || paymentLink === undefined) {
            throw new Error('Required parameter paymentLink was null or undefined when calling apiTransactionsPaymentLinkPaymentLinkCountGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["ApiKey"]) {
            headers = headers.set('ApiKey', this.configuration.apiKeys["ApiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<number>('get',`${this.basePath}/api/Transactions/payment-link/${encodeURIComponent(String(paymentLink))}/count`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param paymentLink 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionsPaymentLinkPaymentLinkGet(paymentLink: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Transactions>>;
    public apiTransactionsPaymentLinkPaymentLinkGet(paymentLink: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Transactions>>>;
    public apiTransactionsPaymentLinkPaymentLinkGet(paymentLink: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Transactions>>>;
    public apiTransactionsPaymentLinkPaymentLinkGet(paymentLink: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (paymentLink === null || paymentLink === undefined) {
            throw new Error('Required parameter paymentLink was null or undefined when calling apiTransactionsPaymentLinkPaymentLinkGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["ApiKey"]) {
            headers = headers.set('ApiKey', this.configuration.apiKeys["ApiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Transactions>>('get',`${this.basePath}/api/Transactions/payment-link/${encodeURIComponent(String(paymentLink))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionsPost(body?: Transactions, observe?: 'body', reportProgress?: boolean): Observable<Transactions>;
    public apiTransactionsPost(body?: Transactions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Transactions>>;
    public apiTransactionsPost(body?: Transactions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Transactions>>;
    public apiTransactionsPost(body?: Transactions, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["ApiKey"]) {
            headers = headers.set('ApiKey', this.configuration.apiKeys["ApiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Transactions>('post',`${this.basePath}/api/Transactions`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionsToPayGet(observe?: 'body', reportProgress?: boolean): Observable<Array<Transactions>>;
    public apiTransactionsToPayGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Transactions>>>;
    public apiTransactionsToPayGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Transactions>>>;
    public apiTransactionsToPayGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["ApiKey"]) {
            headers = headers.set('ApiKey', this.configuration.apiKeys["ApiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Transactions>>('get',`${this.basePath}/api/Transactions/to-pay`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionsToTransferGet(observe?: 'body', reportProgress?: boolean): Observable<Array<Transactions>>;
    public apiTransactionsToTransferGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Transactions>>>;
    public apiTransactionsToTransferGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Transactions>>>;
    public apiTransactionsToTransferGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["ApiKey"]) {
            headers = headers.set('ApiKey', this.configuration.apiKeys["ApiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Transactions>>('get',`${this.basePath}/api/Transactions/to-transfer`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
