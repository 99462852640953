export const locale = {
  lang: 'en',
  data: {
    NAV: {
      TRANSACTIONS: 'Transactions',
      TRANSACTIONSTOTRANSFER: 'Para transferir',
      TRANSACTIONSCANCELED: 'Canceladas',
      TRANSACTIONSPAIDTOESTABLISHMENT: 'Concluídas',
      TRANSACTIONSTRANSFERRED: 'Transferidas',
      TRANSACTIONSFAILED: 'Não aprovadas',
      TRANSACTIONSREQUESTEDTOCANCEL: 'Cancel requested',
      DASHBOARD: 'Dashboard',
      CURRENT_SETTINGS: 'Current Settings',
      CARD_ISSUER_FEES:'Card Issuer Fees',
      USERS: 'Users',
      LOGS: 'Logs',
      ESTABLISHMENTS: 'Establishments',
      POSS: 'POSs',
      PAYMENTLINKS: 'Generated links',
      REPORTS: 'Reports'
    }
  }
};
