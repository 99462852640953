export const environment = {
  appId: 'dreampay-admin',
  name: 'ADMIN',
  env: 'dev',
  production: false,
  hmr: false,
  firebaseConfig: {
    apiKey: 'AIzaSyDnS_5tomQJ5ZdZJY5AgaftR9V33a0qZsA',
    authDomain: 'admin-dreampay.firebaseapp.com',
    projectId: 'admin-dreampay',
    storageBucket: 'admin-dreampay.appspot.com',
    messagingSenderId: '567871195736',
    appId: '1:567871195736:web:c34cb84ad5142fe64e182d',
    measurementId: 'G-R5CGHLHJ8H'
  },
  apiURL: 'https://dev-private-api.dreampay.com.br/api/',
  API_BASE_PATH: 'https://dev-private-api.dreampay.com.br'
};
