import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    translate: 'NAV.DASHBOARD',
    type: 'item',
    icon: 'dashboard',
    url: '/pages/dashboard'
  },
  {
    id: 'reports',
    title: 'Relatórios',
    translate: 'NAV.REPORTS',
    type: 'item',
    icon: 'description',
    url: '/pages/reports'
  },
  {
    id: 'appcurrent-settingss',
    title: 'Configurações atuais',
    translate: 'NAV.CURRENT_SETTINGS',
    type: 'item',
    icon: 'settings',
    url: '/pages/current-settings'
  },
  {
    id: 'card-issuer-fees',
    title: 'Taxa por Bandeira Cartão',
    translate: 'NAV.CARD_ISSUER_FEES',
    type: 'item',
    icon: 'settings',
    url: '/pages/card-issuer-fees'
  },
  {
    id: 'transactions',
    title: 'Transações',
    translate: 'NAV.TRANSACTIONS',
    type: 'collapsable',
    icon: 'credit_card',
    children: [
      {
        id: 'transactions',
        title: 'Todas as Transações',
        translate: 'NAV.TRANSACTIONS',
        type: 'item',
        url: '/pages/transactions'
      },
      {
        id: 'transactions-paid-to-establishment',
        title: 'Concluídas',
        translate: 'NAV.TRANSACTIONSPAIDTOESTABLISHMENT',
        type: 'item',
        url: '/pages/paid-to-establishment'
      },
      {
        id: 'transactions-transfered',
        title: 'Transferidas',
        translate: 'NAV.TRANSACTIONSTRANSFERRED',
        type: 'item',
        url: '/pages/transferred'
      },
      {
        id: 'transactions-to-transfer',
        title: 'Para transferir',
        translate: 'NAV.TRANSACTIONSTOTRANSFER',
        type: 'item',
        url: '/pages/to-transfer'
      },
      {
        id: 'transactions-canceled',
        title: 'Canceladas',
        translate: 'NAV.TRANSACTIONSCANCELED',
        type: 'item',
        url: '/pages/canceled'
      },
      {
        id: 'transactions-requested-to-cancel',
        title: 'Solicitado o cancelamento',
        translate: 'NAV.TRANSACTIONSREQUESTEDTOCANCEL',
        type: 'item',
        url: '/pages/requested-to-cancel'
      },
      {
        id: 'transactions-failed',
        title: 'Não aprovadas',
        translate: 'NAV.TRANSACTIONSFAILED',
        type: 'item',
        url: '/pages/failed'
      }
    ]
  },
  {
    id: 'payment-links',
    title: 'Links gerados',
    translate: 'NAV.PAYMENTLINKS',
    type: 'item',
    icon: 'link',
    url: '/pages/payment-links'
  },
  {
    id: 'logs',
    title: 'Logs',
    translate: 'NAV.LOGS',
    type: 'item',
    icon: 'bug_report',
    url: '/pages/logs'
  },
  {
    id: 'Establishments',
    title: 'Estabelecimentos',
    translate: 'NAV.ESTABLISHMENTS',
    type: 'item',
    icon: 'storefront',
    url: '/pages/establishments'
  },
  {
    id: 'POSs',
    title: 'POSs',
    translate: 'NAV.POSS',
    type: 'item',
    icon: 'phone_android',
    url: '/pages/poss'
  }
];
