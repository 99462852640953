import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

const version = require('../package.json').version;

Sentry.init({
  dsn: 'https://f598499ac79648f7b6330d2fc639143d@o1114358.ingest.sentry.io/6146098',
  release: version,
  environment: environment.env,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [
        'localhost',
        'https://dev-private-api.dreampay.com.br',
        'https://private-api.dreampay.com.br',
        'https://admin.dreampay.com.br',
        'https://dev-admin.dreampay.com.br',
        /^\//
      ],
      routingInstrumentation: Sentry.routingInstrumentation
    })
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((success) => console.log(`Bootstrap success`))
  .catch((err) => console.error(err));
