export const locale = {
  lang: 'pt',
  data: {
    NAV: {
      TRANSACTIONS: 'Transações',
      TRANSACTIONSTOTRANSFER: 'Para transferir',
      TRANSACTIONSCANCELED: 'Canceladas',
      TRANSACTIONSPAIDTOESTABLISHMENT: 'Concluídas',
      TRANSACTIONSTRANSFERRED: 'Transferidas',
      TRANSACTIONSFAILED: 'Não aprovadas',
      TRANSACTIONSREQUESTEDTOCANCEL: 'Solicitado Cancelamento',
      DASHBOARD: 'Dashboard',
      CURRENT_SETTINGS: 'Configurações atuais',
      CARD_ISSUER_FEES:'Taxa por Bandeira Cartão',
      USERS: 'Utilizadores',
      LOGS: 'Logs',
      ESTABLISHMENTS: 'Estabelecimentos',
      POSS: 'POSs',
      PAYMENTLINKS: 'Links gerados',
      PAGAMENTOS: 'Pagamentos',
      REPORTS: 'Relatórios'
    }
  }
};
