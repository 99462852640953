import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { CardIssuerFeesService } from './api/cardIssuerFees.service';
import { CountriesService } from './api/countries.service';
import { CpfService } from './api/cpf.service';
import { DocumentsService } from './api/documents.service';
import { EmailsService } from './api/emails.service';
import { EstablishmentsService } from './api/establishments.service';
import { EstablishmentsAddressesService } from './api/establishmentsAddresses.service';
import { EstablishmentsComplianceFormService } from './api/establishmentsComplianceForm.service';
import { EstablishmentsSignatoriesService } from './api/establishmentsSignatories.service';
import { FilesService } from './api/files.service';
import { IdentityToolkitService } from './api/identityToolkit.service';
import { LogsService } from './api/logs.service';
import { NotificationsService } from './api/notifications.service';
import { PaymentLinksService } from './api/paymentLinks.service';
import { PaymentMethodsService } from './api/paymentMethods.service';
import { PaymentsService } from './api/payments.service';
import { PosService } from './api/pos.service';
import { ReadMethodsService } from './api/readMethods.service';
import { ReturnedsService } from './api/returneds.service';
import { SettingsService } from './api/settings.service';
import { SettingsUSDService } from './api/settingsUSD.service';
import { TransactionsService } from './api/transactions.service';
import { UploadService } from './api/upload.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    CardIssuerFeesService,
    CountriesService,
    CpfService,
    DocumentsService,
    EmailsService,
    EstablishmentsService,
    EstablishmentsAddressesService,
    EstablishmentsComplianceFormService,
    EstablishmentsSignatoriesService,
    FilesService,
    IdentityToolkitService,
    LogsService,
    NotificationsService,
    PaymentLinksService,
    PaymentMethodsService,
    PaymentsService,
    PosService,
    ReadMethodsService,
    ReturnedsService,
    SettingsService,
    SettingsUSDService,
    TransactionsService,
    UploadService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
