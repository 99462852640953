/**
 * DreamPay REST API
 * API to support DreamPay applications
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export class Notification { 
    emailAddress?: string;
    cc?: string;
    bcc?: string;
    subject?: string;
    body?: string;
    emailTitle?: string;
    imageToUse?: string;
    language?: string;
}